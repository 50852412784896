import React from "react";
import "./App.css";
import { Visualizer } from "./Visualizer";

function App() {
  return (
    <div className="App">
      <Visualizer />
    </div>
  );
}

export default App;
